<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">订单流水</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="订单日期">
					<a-range-picker @change="onChange" />
				</a-form-model-item>
				<a-form-model-item label="所属医院">
					<a-select placeholder="请选择" v-model="query.hospital_id">
						<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="结算对象">
					<a-input v-model="query.name" placeholder="结算对象" />
				</a-form-model-item>
				<a-form-model-item label="结算类型">
					<a-select placeholder="请选择" v-model="query.type">
						<a-select-option :value="0"> 全部 </a-select-option>
						<a-select-option :value="5"> 平台 </a-select-option>
						<a-select-option :value="1"> 代理商 </a-select-option>
						<a-select-option :value="2"> 医院 </a-select-option>
						<a-select-option :value="3"> 护士 </a-select-option>
						<a-select-option :value="4"> 运营 </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="结算状态">
					<a-select placeholder="请选择" v-model="query.status">
						<a-select-option :value="0"> 全部 </a-select-option>
						<a-select-option :value="1"> 待结算 </a-select-option>
						<a-select-option :value="2"> 已结算 </a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>,
		<a-table class="main-table" :pagination="false" :columns="columns" :data-source="data" rowKey="equipment_id"
			style="margin-top: 14px" v-viewer>
			<span slot="jiesuan" slot-scope="text, record">
				<a-space>
					{{ (record.type == 0 ? '平台' : record.type == 1 ? '代理商' : record.type == 2 ? '医院' : record.type == 3 ?
						'护士' : "运营") + ':' +
						record.name }}
				</a-space>
			</span>
			<span slot="status" slot-scope="text, record">
				<a v-if="record.status == 1" style="color: #e0352b;">待结算</a>
				<a v-if="record.status != 1" style="color: #49e02b;">已结算</a>
			</span>
			<span slot="money_type" slot-scope="text, record">
				{{ record.money_type == 1 ? '下单分红' : '退款红冲' }}
			</span>
			<span slot="money_happen" slot-scope="text, record">
				<a v-if="record.money_type == 1" style="color: #e0352b;">+&nbsp;{{ record.settlement_money }}</a>
				<a v-if="record.money_type != 1" style="color: #49e02b;">-&nbsp;{{ record.money_happen }}</a>
			</span>
			<span slot="money" slot-scope="text, record">
				{{ record.money_type == 1 ? "+" : "-" }}&nbsp;{{ Math.abs(record.money) }}
			</span>
			<span slot="scale" slot-scope="text, record">
				{{ record.scale }}%
			</span>
		</a-table>
		<!-- :pagination="{
				current: query.page,
				total: pagetotal
			}"
			@change="listChange" -->
		<div class="money-info">
			<div class="info-box">
				<span class="text"> 统计:{{ followInfo.time_range }} </span>
				<span class="text"> 总金额: {{ followInfo.order_money }} 元</span>
				<span class="text"> 总退款: {{ followInfo.refund_money }}元 </span>
				<span class="实际分红"> 分红: {{ followInfo.share_money }}元 </span>
			</div>
		</div>
		<div style="padding: 20px 0; display: flex; justify-content: flex-end">
			<a-pagination @change="listChange" :current="query.page" :total="pagetotal"
				:showTotal="(pagetotal, range) => `共 ${pagetotal} 条数据`" />
		</div>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			followInfo: {
				time_range: '',
				share_money: '',
				refund_money: '',
				order_money: ''
			},
			equipment_id: null,
			equipment_info: {},
			hospitals: [],
			departments: [],
			pagetotal: 0,
			ks_visible: false,
			bindObj: {},
			visible: false,
			confirmLoading: false,
			equipment: null,
			columns: [
				{
					title: '序号',
					customRender: (text, record, index) => index + 1
				},
				{
					title: '订单编号',
					dataIndex: 'order_no'
				},
				{
					title: '所属医院',
					dataIndex: 'hospital_name'
				},
				{
					title: '结算对象',
					scopedSlots: {
						customRender: 'jiesuan'
					}
				},
				{
					title: '状态',
					scopedSlots: {
						customRender: 'status'
					}
				},
				{
					title: '订单实收',
					dataIndex: 'settlement_money'
				},
				{
					title: '本次产生',
					scopedSlots: {
						customRender: 'money_happen'
					}
				},
				{
					title: '产生类型',
					scopedSlots: {
						customRender: 'money_type'
					}
				},
				{
					title: '分红/元',
					scopedSlots: {
						customRender: 'money'
					}
				},
				{
					title: '分红比例',
					scopedSlots: {
						customRender: 'scale'
					}
				},
				{
					title: '创建时间',
					dataIndex: 'create_at_str'
				}
			],
			data: [],
			selectedRowKeys: [],
			info_rules: {
				equipment_number: [
					{
						required: true,
						message: '填写设备编号',
						trigger: 'blur'
					}
				],
				hardware_number: [
					{
						required: true,
						message: '填写硬件编号',
						trigger: 'blur'
					}
				]
			},

			bindRules: {
				department_id: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'blur'
					}
				]
			}
		}
	},
	created() {
		this.customer_id = this.$route.query.cid
		this.getList()
		this.getHospitals()
	},
	methods: {
		getHospitals() {
			this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
				if (res.code === 0) {
					this.hospitals = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleCancel() {
			this.visible = false
			this.initForm()
		},
		handleCancelBind() {
			this.ks_visible = false
			this.ksInitForm()
		},
		add() {
			this.visible = true
		},
		toEdit(item) {
			this.equipment_info = item
			this.equipment_id = item.equipment_id
			this.visible = true
		},
		allImport() { },
		initForm() {
			this.equipment_info = {}
			this.equipment_id = null
		},
		onChange(a, b) {
			this.query.begin_time = b[0]
			this.query.end_time = b[1]
			this.getList()
		},
		ksInitForm() {
			this.bindObj = {}
		},
		submitInfo() {
			this.$refs.equipmentForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					let url = this.equipment_id ? 'equipmentEdit' : 'equipmentAdd'
					if (this.equipment_id) {
						this.equipment_info.equipment_id = this.equipment_id
					}
					this.$post('equipment/' + url, this.equipment_info)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.handleCancel()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		submitBind() {
			this.$refs.bindForm.validate(valid => {
				if (valid) {
					let obj = {
						department_id: this.bindObj.department_id,
						equipment_ids: this.selectedRowKeys
					}
					this.$post('equipment/equipmentBind', obj)
						.then(res => {
							let { code, msg, data } = res
							if (code == 0) {
								this.$message.success(msg, 4)
								this.ks_visible = false
								this.ksInitForm()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		hospitalChange(name, obj) {
			this.$post(domain + '/admin/v1/hospital/departmentList', {
				hospital_id: obj.data.key,
				limit: 10000,
				page: 1
			}).then(res => {
				if (res.code === 0) {
					this.departments = res.data.list
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toBind() {
			if (this.selectedRowKeys.length == 0) {
				this.$message.error('请先选择要绑定的设备', 1.5)
			} else {
				this.ks_visible = true
				this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
					if (res.code === 0) {
						this.hospitals = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			}
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/reconciliation/reconciliationFlowingWater', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
					this.followInfo.refund_money = res.data.refund_money
					this.followInfo.share_money = res.data.share_money
					this.followInfo.order_money = res.data.order_money
					this.followInfo.time_range = res.data.time_range
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			console.log(e)
			this.query.page = e
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		},
		changeCheck(value) {
			if (value.length === 1) {
				this.query.is_on = value[0]
			} else {
				this.query.is_on = null
			}
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			console.log('选中中', selectedRowKeys)
			this.selectedRowKeys = selectedRowKeys
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}

.money-info {
	display: flex;
	justify-content: flex-end;
	background: #eaecf3;
	padding: 10px 15px;
	line-height: 40px;

	.info-box {
		color: #4e80f8;

		.text {
			margin-left: 10px;
		}
	}
}
</style>
